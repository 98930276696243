<template>
  <section class="space-y-10">
    <QuickInfo />

    <asom-alert v-if="error" variant="error" :error-message="error" />

    <article>
      <h3 class="subheader">
        New Cashbox
      </h3>
      <br />
      <asom-card>  
        <asom-grid :sm="1" :md="2">
          <asom-form-field :label="'From'" class="">
            <p class="mt-3 text-sm text-gray-500">External</p>
          </asom-form-field>
          <asom-form-field :label="'To'" class="">
            <p class="mt-3 text-sm text-gray-500">AFC</p>
          </asom-form-field>
          <asom-form-field :label="'Input Type'" class="col-span-2">
            <asom-input-radio-group
              class="mt-3"
              horizontal
              v-model="formData.isNoteBox"
              :options="cashBoxTypeOptions"
            />
          </asom-form-field>
          <asom-form-field
            label="Cash Box Serial Number"
            required
            :state="inputStates('formData.cashBoxSerialNo')"
            error="Cash Box Serial Number is required"
          >
            <scan-code
              v-model="formData.cashBoxSerialNo"
              :state="inputStates('formData.cashBoxSerialNo')"
            />
          </asom-form-field>
          <div></div>

          <asom-form-field :label="'Document Ref'">
            <asom-input-text v-model="formData.documentReference" />
          </asom-form-field>
          <div></div>
          <asom-form-field :label="'Remarks'">
            <asom-input-textarea v-model="formData.remarks" />
          </asom-form-field>
        </asom-grid>

        <template #footer>
          <div class="flex justify-between flex-1">
            <asom-button
              variant="link"
              text="Cancel"
              @click="$router.push({ name: 'Cashbox Main Page' })"
            />
            <asom-button text="Confirm" @click="onConfirmBtnClicked" />
          </div>
        </template>
      </asom-card>
    </article>
  </section>

  <asom-modal title="Confirm" v-model="showModal" :dismissible="false">
    <p class="pt-4">Are you sure you would like to proceed?</p>

    <div class="flex flex-row-reverse pt-4">
      <div class="pl-4">
        <asom-button
          @click="onSubmit"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          text="OK"
        />
      </div>
      <div>
        <asom-button @click="onCloseModal" text="Cancel" variant="secondary" />
      </div>
    </div>
  </asom-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { createCashBox } from "@/services/cashManagement.service";
import QuickInfo from "../../_QuickInfo.vue";
import ScanCode from "../../_ScanCode.vue";
export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    QuickInfo,
    ScanCode,
  },
  data() {
    return {
      isSubmitting: false,
      error: null,
      showModal: false,
      formData: {
        cashBoxSerialNo: "",
        isNoteBox: true,
        documentReference: "",
        remarks: "",
      },
    };
  },
  validations() {
    return {
      formData: {
        cashBoxSerialNo: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
    }),
    cashBoxTypeOptions() {
      return [
        { label: "Note Box", value: true },
        { label: "Coin Box", value: false },
      ];
    },
  },
  methods: {
    onConfirmBtnClicked() {
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.error = "Please complete all required fields.";
        this.$scrollTop();
        return;
      } else {
        this.error = null;
        this.showModal = true;
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      this.error = null;
      const result = await createCashBox({
        AFCId: this.afcId,
        CashBoxSerialNo: this.formData.cashBoxSerialNo,
        isNoteBox: this.formData.isNoteBox,
        documentReference: this.formData.documentReference,
        remarks: this.formData.remarks,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      this.isSubmitting = false;
      if (!result.success) {
        this.error = result.payload;
        this.showModal = false;
        this.$scrollTop();
      } else {
        this.$router.push({ name: "Cashbox Main Page" });
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
